import { createAsyncThunk } from '@reduxjs/toolkit';
import { postCreateDefectFix } from 'scenes/DefectList/services/post-create-defect-fix.service';
import {
  selectDefectFixAttributes,
  selectDefectImagesToAdd,
  selectSelectedDefectItemIdToFix,
} from '../selectors/defect-list.selectors';

export const createDefectFix = createAsyncThunk(
  'defectLists/createDefectFix',
  async (_, { getState }) => {
    const defectItemIdToFix = selectSelectedDefectItemIdToFix(getState());

    const defectFixAttributes = selectDefectFixAttributes(getState());

    const defectImagesToAdd = selectDefectImagesToAdd(getState());

    const updateData = await postCreateDefectFix(
      defectItemIdToFix,
      defectFixAttributes,
      defectImagesToAdd
    );

    return updateData;
  }
);
