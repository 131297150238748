import i18n from 'i18next';

const RECORD_STATUS = Object.freeze({
  IN: 'IN',
  OUT: 'OUT',
});

const RECORD_TYPE = Object.freeze({
  ABSENCE: 'ABSENCE',
  BENEFIT: 'BENEFIT',
  BREAK: 'BREAK',
  BREAK_COFFEE: 'BREAK_COFFEE',
  BREAK_MEAL: 'BREAK_MEAL',
  ITEM: 'ITEM',
  RIDE: 'RIDE',
  SHIFT: 'SHIFT',
  WORK: 'WORK',
  PROJECTWORK: 'PROJECTWORK',
  ABSENCE_SICK_LEAVE: 'ABSENCE_SICK_LEAVE',
  ABSENCE_VACATION_PAID: 'ABSENCE_VACATION_PAID',
});

const BUSINESS_TYPE = Object.freeze({
  CUSTOMER: 'CUSTOMER',
  EMPLOYER: 'EMPLOYER',
  OWNER: 'OWNER',
  RENTAL_EMPLOYER: 'RENTAL_EMPLOYER',
  SUBCONTRACTOR: 'SUBCONTRACTOR',
  NORMAL: 'NORMAL',
  FOLDER: 'FOLDER',
});

const EMPLOYMENT_TYPE = Object.freeze({
  EMPLOYEE: 'EMPLOYEE',
  PRACTITIONER: 'PRACTITIONER',
  RENTAL_EMPLOYEE: 'RENTAL_EMPLOYEE',
  TRAINEE: 'TRAINEE',
});

const FILEPERMISSION_TYPE = Object.freeze({
  FILE: 'FILE',
  FOLDER: 'FOLDER',
});

const ISO_8601_FORMAT = Object.freeze({
  DATE: 'YYYY-MM-DD',
});

const FILEPERMISSION_STATIC_FOLDER = Object.freeze({
  IMAGES: 'images',
  DOCUMENTS: 'documents',
  REPORTS: 'reports',
});

const TAXNUMBER_STATUS = Object.freeze({
  UNCHECKED: 'UNCHECKED', // If taxnumber service is down
  INVALID: 'INVALID', // If taxnumber is invalid by taxnumber check service
  VALID: 'VALID', // If taxnumber is valid by taxnumber check service
});

const DEFAULT_ADDITIONAL_SALARY_TYPES = {
  FULL_DAILY_ALLOWANCE: {
    salaryName: 'report.salaries.full_daily_allowance',
    salaryType: 'FULL_DAILY_ALLOWANCE',
    procountorSalaryCode: '4010',
    unit: 'report.pieces',
  },
  DAILY_ALLOWANCE_HALF_DAY: {
    salaryName: 'report.salaries.daily_allowance_half_day',
    salaryType: 'DAILY_ALLOWANCE_HALF_DAY',
    procountorSalaryCode: '4020',
    unit: 'report.pieces',
  },
  KILOMETER_ALLOWANCE: {
    salaryName: 'report.salaries.kilometer_allowance',
    salaryType: 'KILOMETER_ALLOWANCE',
    procountorSalaryCode: '4050',
    unit: 'report.pieces',
  },
  TRAVEL_EXPENSE_COMPENSATION: {
    salaryName: 'report.salaries.travel_expense_compensation',
    salaryType: 'TRAVEL_EXPENSE_COMPENSATION',
    procountorSalaryCode: '4060',
    unit: 'report.pieces',
  },
  SICK_LEAVE_PAY: {
    salaryName: 'report.salaries.sick_leave_pay',
    salaryType: 'SICK_LEAVE_PAY',
    procountorSalaryCode: '2410',
    unit: 'report.pieces',
  },
  HOLIDAY_PAY: {
    salaryName: 'report.salaries.holiday_pay',
    salaryType: 'HOLIDAY_PAY',
    procountorSalaryCode: '2201',
    unit: 'report.pieces',
  },
  TAXABLE_REIMBURSEMENT_OF_EXPENSES: {
    salaryName: 'report.salaries.taxable_reimbursement_of_expenses',
    salaryType: 'TAXABLE_REIMBURSEMENT_OF_EXPENSES',
    procountorSalaryCode: '2671',
    unit: 'report.pieces',
  },
  WORKING_HOUR_REDUCTION_COMPENSATION: {
    salaryName: 'report.salaries.working_hour_reduction_compensation',
    salaryType: 'WORKING_HOUR_REDUCTION_COMPENSATION',
    procountorSalaryCode: '1459',
    unit: 'report.pieces',
  },
  MEAL_ALLOWANCE: {
    salaryName: 'report.salaries.meal_allowance',
    salaryType: 'MEAL_ALLOWANCE',
    procountorSalaryCode: '4030',
    unit: 'report.pieces',
  },
};

export {
  RECORD_STATUS,
  RECORD_TYPE,
  ISO_8601_FORMAT,
  BUSINESS_TYPE,
  EMPLOYMENT_TYPE,
  FILEPERMISSION_TYPE,
  TAXNUMBER_STATUS,
  FILEPERMISSION_STATIC_FOLDER,
  DEFAULT_ADDITIONAL_SALARY_TYPES,
};
