import { createAction } from '@reduxjs/toolkit';

export const clearExpensesTrackingData = createAction(
  'expensesTracking/clearExpensesTrackingData'
);

export const clearExpenseTrackingForDateChange = createAction(
  'expensesTracking/clearExpenseTrackingForDateChange'
);

export const getProjectAssignmentTree = createAction(
  'expenseTracking/getProjectAssignmentTree'
);

export const setAllProjectAssignmentsLoaded = createAction(
  'expenseTracking/setAllProjectAssignmentsLoaded'
);

export const setFilters = createAction(
  'expenseTracking/setActiveFilters'
);

export const addFilter = createAction(
  'expenseTracking/addFilter'
);

export const removeFilter = createAction(
  'expenseTracking/removeFilter'
);

export const resetFilters = createAction(
  'expenseTracking/resetFilters'
);

export const setAllProjectAssignmentsLoadedAmount = createAction(
  'expenseTracking/setAllProjectAssignmentsLoadedAmount'
);


export const updateAssignmentsFromGraphqlMutate = createAction(
  'expenseTracking/updateAssignmentsFromGraphqlMutate'
);
