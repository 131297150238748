import { createAction } from '@reduxjs/toolkit';

export const setSortBy = createAction('projectBank/setSortBy');

export const closeProjectBank = createAction('projectBank/closeProjectBank');

export const setSelectedTilaajavastuuBusinessId = createAction(
  'projectBank/setSelectedTilaajavastuuBusinessId'
);

export const openProjectTilaajavastuuFolder = createAction(
  'project-bank/openProjectTilaajavastuuFolder'
);

export const openProjectTreeFolders = createAction(
  'project-bank/openProjectTreeFolders'
);

export const closeProjectTreeFolders = createAction(
  'project-bank/closeProjectTreeFolders'
);

export const openPdfPreviewDialog = createAction(
  'project-bank/openPdfPreviewDialog'
);

export const closePdfPreviewDialog = createAction(
  'project-bank/closePdfPreviewDialog'
);

export const setProjectBankViewMode = createAction(
  'project-bank/setProjectBankViewMode'
);

export const toggleSelectProjectBankFile = createAction(
  'project-bank/toggleSelectProjectBankFile'
);

export const clearSelectedProjectBankFiles = createAction(
  'project-bank/clearSelectedProjectBankFiles'
);

export const setIsProjectBankFileSelectDialogOpen = createAction(
  'project-bank/setIsProjectBankFileSelectDialogOpen'
);
