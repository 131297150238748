import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const selectWorkSearchParams = (state) => state.workSearchParams_demo;

export const selectSelectedEmployersBusinessIds = createSelector(
  [selectWorkSearchParams],
  (params) => params.selectedEmployerBusinessIds
);

export const selectSelectedProjectIds = createSelector(
  [selectWorkSearchParams],
  (params) => params.selectedProjects.ids
);

export const selectSelectedProjectOptions = createSelector(
  [selectWorkSearchParams],
  (params) => Object.values(params.selectedProjects.entities)
);

export const selectSelectedUserIds = createSelector(
  [selectWorkSearchParams],
  (params) => params.selectedUsers.ids
);

export const selectSelectedUserOptions = createSelector(
  [selectWorkSearchParams],
  (params) => Object.values(params.selectedUsers.entities)
);

export const selectSelectedAssignmentIds = createSelector(
  [selectWorkSearchParams],
  (params) => params.selectedAssignment.ids
);

export const selectWorkSearchParamsVisibleWeeks = createSelector(
  [selectWorkSearchParams],
  (params) => params.visibleWeeks
);

export const selectWorkSearchParamsDescriptionSearchText = createSelector(
  [selectWorkSearchParams],
  (params) => params.descriptionSearchText
);

export const selectWorkSearchComponentStateToDate = createSelector(
  [selectWorkSearchParams],
  (state) => state.componentStateToDate
);

export const selectWorkSearchComponentStateFromDate = createSelector(
  [selectWorkSearchParams],
  (state) => state.componentStateFromDate
);

export const selectWorkSearchParamsFromDate = createSelector(
  [selectWorkSearchParams],
  (params) => params.fromDate
);
export const selectWorkSearchParamsToDate = createSelector(
  [selectWorkSearchParams],
  (params) => params.toDate
);

export const selectSelectedResourceIds = createSelector(
  [selectWorkSearchParams],
  (params) => params.selectedResourceIds
);

export const selectWorkSearchStateFromDateAndToDate = createSelector(
  [
    selectWorkSearchComponentStateFromDate,
    selectWorkSearchComponentStateToDate,
  ],
  (fromDate, toDate) => ({ fromDate, toDate })
);

export const selectMapDialogData = createSelector(
  [selectWorkSearchParams],
  (params) => params.showMap
);

export const selectSelectionMode = createSelector(
  [selectWorkSearchParams],
  (state) => state.selectionMode
);

export const selectWeeks = createSelector(
  selectWorkSearchParamsFromDate,
  selectWorkSearchParamsToDate,
  (from, to) => {
    const fromDate = dayjs(from);
    const toDate = dayjs(to);

    let weeksArray = [];

    for (
      let i = fromDate;
      i.endOf('isoWeek').format('YYYY-WW') <=
      toDate.endOf('isoWeek').format('YYYY-WW');
      i = i.add(1, 'week')
    ) {
      weeksArray.push({
        weekStart: i.startOf('isoWeek'),
        weekEnd: i.endOf('isoWeek'),
      });
    }

    return weeksArray;
  }
);

export const selectFetchedWeekNumbers = createSelector(
  [selectWeeks],
  (weeks) => {
    const weekNumbers = weeks.map(
      (week) => week.weekEnd.format('GGGG[W]WW').split('W')[1]
    );

    return weekNumbers;
  }
);

export const selectSelectedApprovalGroupCodes = createSelector(
  [selectWorkSearchParams],
  (state) => state.selectedApprovalGroupCodes
);

export const selectIsSelectedApprovalGroups = createSelector(
  [selectWorkSearchParams],
  (state) => state.selectedApprovalGroupCodes.length > 0
);
