import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { DEFAULT_ADDITIONAL_SALARY_TYPES } from 'LixaniAPI/enums';
import { round } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { generateReportFromSelectedRecords } from 'scenes/WorkDemo/store/thunks/reports/generateReportFromSelectedRecords.thunk';
import { generateReportFromWorkCalendarData } from 'scenes/WorkDemo/store/thunks/reports/generateReportFromWorkCalendarData.thunk';
import { generateReportPreviewTableFromSelectedRecords } from 'scenes/WorkDemo/store/thunks/reports/generateReportPreviewTableFromRecords.thunk';
import { arrayToMap } from 'utils/arrayToMap';
import { returnDateAndIsoWeekDayFromReportDayValue } from 'utils/returnDateAndIsoWeekDayFromReportDayValue.util';
import {
  addAdditionalUserSalaryRow,
  addManualUserRowTotals,
  addSalaryRowsRecipientEmail,
  closeAdditionalUserSalaryRowDialog,
  closeConfirmReportDraftDeleteDialog,
  closeReportDraftsDialog,
  closeReportPreviewDialog,
  closeReportSendDialog,
  closeRowCommentDialog,
  initAddEmployeeForm,
  openAdditionalUserSalaryRowDialog,
  openConfirmReportDraftDeleteDialog,
  openNextReport,
  openPreviousReport,
  openReportDraftsDialog,
  openReportSendDialog,
  openReportsHistory,
  openRowCommentDialog,
  removeReportPreviewUserAdditionalSalaryRow,
  removeReportPreviewUserRow,
  removeSalaryRowsRecipientEmail,
  setDayWorkHoursOfEmployeeToAdd,
  setEmployeeNameToAdd,
  setInitialReportEditValuesOfDraft,
  setReportGeneratedFromMode,
  setRowComment,
  setSelectedReportDraftId,
  toggleIsReportProjectRowsEnabled,
  toggleRecordBasedReductions,
} from './actions/reports.actions';
import { addReportPreviewUserRow } from './thunks/addReportPreviewUserRow.thunk';
import { deleteReportDraft } from './thunks/deleteReportDraft.thunk';
import { generateProjectEmployeeReport } from './thunks/generateProjectEmployeeReport.thunk';
import { generateProjectOrientatedEmployeesReport } from './thunks/generateProjectOrientatedEmployeesReport.thunk';
import { loadProjectSavedReports } from './thunks/loadProjectSavedReports';
import { sendProcountorSalaryData } from './thunks/sendProcountorSalaryData.thunk';

const savedReportsAdapter = createEntityAdapter({
  selectId: (savedReport) => `${savedReport.PK}`,
});

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    generatedReport: {},
    isRecordBasedReductionsActive: false,
    isReportProjectHourRowsEnabled: false,
    reportProcessedData: {},
    reportBasicData: {},
    reportGeneratedFromMode: 'ALL',
    reportRecipientEmails: [],
    tableDataByWeekPeriod: [],
    weekIndexedEmployeeNamesToAdd: {},
    weekIndexedDayWorkHoursOfEmployeeToAdd: {},
    reportUserRowTotals: {},
    rowComments: {},
    selectedRowCommentData: {},
    additionalUserSalaryRows: {},
    selectedReportDraftId: '',
    // loading states
    loading: false,
    isSendingSalaryData: false,
    isDeletingReportDraft: false,
    // dialogs
    isReportSendDialogOpen: false,
    isRowCommentDialogOpen: false,
    isAdditionalSalaryRowDialogOpen: false,
    isReportDraftsDialogOpen: false,
    isConfirmReportDraftDeleteDialogOpen: false,
    additionalUserSalaryRowDialogData: {},
    // saved reports
    savedReports: savedReportsAdapter.getInitialState(),
    isReportsHistoryOpen: false,
    currentOpenHistoryReportIndex: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(generateReportFromSelectedRecords.pending, (state, action) => {
        state.generatedReport = {};
        state.loading = true;
      })
      .addCase(generateReportFromSelectedRecords.fulfilled, (state, action) => {
        state.generatedReport = action.payload.documentData;
        state.reportProcessedData = action.payload.processedData;
        state.loading = false;
      })
      .addCase(generateReportFromWorkCalendarData.pending, (state, action) => {
        state.generatedReport = {};
        state.loading = true;
      })
      .addCase(
        generateReportFromWorkCalendarData.fulfilled,
        (state, action) => {
          state.generatedReport = action.payload.documentData;
          state.loading = false;
        }
      )
      .addCase(
        generateReportPreviewTableFromSelectedRecords.pending,
        (state, action) => {
          state.loading = true;
        }
      )
      .addCase(
        generateReportPreviewTableFromSelectedRecords.fulfilled,
        (state, action) => {
          const processedData = action.payload.processedData;

          const isRecordBasedReductionsActive =
            state.isRecordBasedReductionsActive;

          state.reportBasicData = {
            fromDate: processedData.fromDate,
            toDate: processedData.toDate,
            reportType: processedData.reportType,
            isRecordBasedReductionsActive,
          };

          const tableRowsByWeekIndex = processedData.dataRows;

          const weekTablesData = processedData.headerRows.map(
            (headerRow, index) => ({
              headerRow: headerRow,
              dataRows: tableRowsByWeekIndex[index],
            })
          );

          state.tableDataByWeekPeriod = weekTablesData;

          let totalsToSet = {};

          const userTotalWorkAmountsByUserId =
            processedData.userTotalWorkAmountsByUserId;

          Object.values(userTotalWorkAmountsByUserId).forEach(
            (userTotalAmount) => {
              const userId = userTotalAmount.userId;
              const userResourceData = processedData.resources[userId];

              const { baseSalaryType, unitPrice, monthSalaryFrequency } =
                userTotalAmount;

              const isMonthlyBaseSalary = baseSalaryType === 'MONTHLY';

              const salaryType = isMonthlyBaseSalary
                ? 'WORK_SALARY_MONTHLY'
                : 'WORK_SALARY_HOURLY';

              const userName = `${userResourceData.resource.firstName} ${userResourceData.resource.lastName}`;

              totalsToSet[userId] = {
                name: userName,
                salaryType,
                baseSalaryType,
                unitCount: isMonthlyBaseSalary
                  ? 1
                  : userTotalAmount.workReducedHours,
                unitCountWithoutReductions: userTotalAmount.workTotalHours,
                unitPrice,
                userId,
                projectTotals: userTotalAmount.projectTotals,
              };

              // in case of semi monthly salary, add additional rows based on defined settings for monthly salary payment
              if (
                isMonthlyBaseSalary &&
                monthSalaryFrequency === 'SEMI_MONTHLY'
              ) {
                const unitCount = -0.5;

                state.additionalUserSalaryRows[userId] = [
                  {
                    salaryName: 'Peruspalkan vähennys',
                    salaryType: 'BASE_SALARY_DEDUCTION',
                    unit: 'kpl',
                    userId,
                    userName,
                    unitCount: -0.5,
                    unitPrice: Number(unitPrice),
                    calculatedTotal: unitCount * Number(unitPrice),
                  },
                ];

                const rowCommentId = `${userId}-BASE_SALARY_DEDUCTION`;

                state.rowComments[rowCommentId] =
                  'Puolen kuukauden palkan vähennys.';
              }

              const additionalUserSalaries = Object.values(
                processedData.resources[userId].additionalSalaries
              );

              if (additionalUserSalaries.length > 0) {
                const additionalSalaryRows = additionalUserSalaries.map(
                  (additionalSalary) => {
                    const defaultSalaryTypeData =
                      DEFAULT_ADDITIONAL_SALARY_TYPES[
                        additionalSalary.salaryType
                      ];

                    return {
                      salaryName: i18n.t(defaultSalaryTypeData.salaryName),
                      procountorSalaryCode:
                        defaultSalaryTypeData.procountorSalaryCode,
                      salaryType: additionalSalary.salaryType,
                      unitCount: round(additionalSalary.unitCount, 2),
                      definedDates: additionalSalary.definedDates,
                      dayUnitCount: additionalSalary.dayUnitCount,
                      unit: 'kpl',
                      userId,
                      userName,
                    };
                  }
                );

                const existingUserAdditionalSalaries =
                  state.additionalUserSalaryRows[userId];
                if (existingUserAdditionalSalaries) {
                  existingUserAdditionalSalaries.push(additionalSalaryRows);
                } else {
                  state.additionalUserSalaryRows[userId] = additionalSalaryRows;
                }
              }
            }
          );

          state.reportUserRowTotals = totalsToSet;
          state.loading = false;
        }
      )
      .addCase(setReportGeneratedFromMode, (state, action) => {
        state.reportGeneratedFromMode = action.payload;
      })
      .addCase(generateProjectEmployeeReport.pending, (state, action) => {
        state.generatedReport = {};
        state.loading = true;
      })
      .addCase(generateProjectEmployeeReport.fulfilled, (state, action) => {
        state.generatedReport = action.payload;
        state.loading = false;
      })
      .addCase(
        generateProjectOrientatedEmployeesReport.pending,
        (state, action) => {
          state.generatedReport = {};
          state.loading = true;
        }
      )
      .addCase(
        generateProjectOrientatedEmployeesReport.fulfilled,
        (state, action) => {
          state.generatedReport = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        generateProjectOrientatedEmployeesReport.rejected,
        (state, action) => {
          state.loading = false;
        }
      )
      .addCase(sendProcountorSalaryData.pending, (state, action) => {
        state.isSendingSalaryData = true;
      })
      .addCase(sendProcountorSalaryData.fulfilled, (state, action) => {
        state.isSendingSalaryData = false;
      })
      .addCase(sendProcountorSalaryData.rejected, (state, action) => {
        state.isSendingSalaryData = false;
      })
      .addCase(openReportSendDialog, (state, action) => {
        state.isReportSendDialogOpen = true;
      })
      .addCase(closeReportSendDialog, (state, action) => {
        state.isReportSendDialogOpen = false;
        state.reportRecipientEmails = [];
      })
      .addCase(addSalaryRowsRecipientEmail, (state, action) => {
        state.reportRecipientEmails.push(action.payload);
      })
      .addCase(removeSalaryRowsRecipientEmail, (state, action) => {
        state.reportRecipientEmails = state.reportRecipientEmails.filter(
          (email) => email !== action.payload
        );
      })
      .addCase(setEmployeeNameToAdd, (state, action) => {
        state.weekIndexedEmployeeNamesToAdd[action.payload.weekIndex] =
          action.payload.name;
      })
      .addCase(setDayWorkHoursOfEmployeeToAdd, (state, action) => {
        const weekIndex = action.payload.weekTableIndex;

        const dayToSet = action.payload.dayToSet;

        const weekIndexedHours = {
          ...state.weekIndexedDayWorkHoursOfEmployeeToAdd,
        };

        const weekDayValues = weekIndexedHours[weekIndex];

        weekDayValues[dayToSet.day] = dayToSet;

        weekIndexedHours[weekIndex] = weekDayValues;

        state.weekIndexedDayWorkHoursOfEmployeeToAdd = weekIndexedHours;
      })
      .addCase(addReportPreviewUserRow.fulfilled, (state, action) => {
        state.tableDataByWeekPeriod = action.payload;
      })
      .addCase(removeReportPreviewUserRow, (state, action) => {
        const userId = action.payload.userId;

        state.tableDataByWeekPeriod.forEach((weeklyTableData, index) => {
          const filteredRows = weeklyTableData.dataRows.filter(
            (weekRow) => weekRow.userId !== userId
          );
          state.tableDataByWeekPeriod[index] = {
            ...state.tableDataByWeekPeriod[index],
            dataRows: filteredRows,
          };
        });

        delete state.reportUserRowTotals[userId];
        delete state.additionalUserSalaryRows[userId];
      })
      .addCase(addManualUserRowTotals, (state, action) => {
        const existingManualUserRow = Object.values(
          state.reportUserRowTotals
        ).find((userRow) => userRow.name === action.payload.name);

        if (existingManualUserRow) {
          const combinedUnitCountWithoutReductions =
            (existingManualUserRow.unitCountWithoutReductions +=
              action.payload.unitCountWithoutReductions);

          const combinedUnitCount = (existingManualUserRow.unitCount +=
            action.payload.unitCount);

          state.reportUserRowTotals[action.payload.userId] = {
            ...action.payload,
            unitCountWithoutReductions: combinedUnitCountWithoutReductions,
            unitCount: combinedUnitCount,
          };
        } else {
          state.reportUserRowTotals[action.payload.userId] = action.payload;
        }
      })
      .addCase(initAddEmployeeForm, (state, action) => {
        const weekTableIndex = action.payload;

        const weekPeriodDays =
          state.tableDataByWeekPeriod[weekTableIndex].headerRow;

        const defaultValues = weekPeriodDays.map((dayValue) => {
          const { date } = returnDateAndIsoWeekDayFromReportDayValue(dayValue);
          return {
            day: date,
            value: '',
          };
        });

        const weekIndexedHours = state.weekIndexedDayWorkHoursOfEmployeeToAdd;

        weekIndexedHours[weekTableIndex] = arrayToMap(defaultValues, 'day');

        state.weekIndexedDayWorkHoursOfEmployeeToAdd = weekIndexedHours;
      })
      .addCase(openRowCommentDialog, (state, action) => {
        state.isRowCommentDialogOpen = true;
        state.selectedRowCommentData = action.payload;
      })
      .addCase(closeRowCommentDialog, (state, action) => {
        state.isRowCommentDialogOpen = false;
        state.selectedRowCommentData = {};
      })
      .addCase(setRowComment, (state, action) => {
        const comment = action.payload.comment;
        const rowCommentId = action.payload.salaryType
          ? `${action.payload.userId}-${action.payload.salaryType}`
          : `${action.payload.userId}`;

        state.rowComments[rowCommentId] = comment;
        state.selectedRowCommentData = {};
        state.isRowCommentDialogOpen = false;
      })
      .addCase(closeReportPreviewDialog, (state, action) => {
        state.rowComments = {};
        state.additionalUserSalaryRows = {};
        state.weekIndexedEmployeeNamesToAdd = {};
        state.currentOpenHistoryReportIndex = 0;
        state.isReportsHistoryOpen = false;
      })
      .addCase(openAdditionalUserSalaryRowDialog, (state, action) => {
        state.isAdditionalSalaryRowDialogOpen = true;
        state.additionalUserSalaryRowDialogData = action.payload;
      })
      .addCase(closeAdditionalUserSalaryRowDialog, (state, action) => {
        state.isAdditionalSalaryRowDialogOpen = false;
      })
      .addCase(removeReportPreviewUserAdditionalSalaryRow, (state, action) => {
        const userId = action.payload.userId;

        state.additionalUserSalaryRows[userId] = state.additionalUserSalaryRows[
          userId
        ].filter((row) => row.salaryType !== action.payload.salaryType);
      })
      .addCase(addAdditionalUserSalaryRow, (state, action) => {
        if (!state.additionalUserSalaryRows[action.payload.userId]) {
          state.additionalUserSalaryRows[action.payload.userId] = [
            action.payload,
          ];
        } else {
          state.additionalUserSalaryRows[action.payload.userId].push(
            action.payload
          );
        }
        state.isAdditionalSalaryRowDialogOpen = false;
      })
      .addCase(loadProjectSavedReports.fulfilled, (state, action) => {
        savedReportsAdapter.setMany(state.savedReports, action.payload);
      })
      .addCase(deleteReportDraft.pending, (state, action) => {
        state.isDeletingReportDraft = true;
      })
      .addCase(deleteReportDraft.fulfilled, (state, action) => {
        savedReportsAdapter.upsertOne(state.savedReports, action.payload);
        enqueueSnackbar(i18n.t('report.draft_deletion_success'), {
          variant: 'success',
        });
        state.isDeletingReportDraft = false;
        state.isConfirmReportDraftDeleteDialogOpen = false;
        state.selectedReportDraftId = '';
      })
      .addCase(deleteReportDraft.rejected, (state, action) => {
        state.isDeletingReportDraft = false;
        enqueueSnackbar(i18n.t('report.draft_deletion_failed'), {
          variant: 'error',
        });
      })
      .addCase(openReportsHistory, (state, action) => {
        state.isReportsHistoryOpen = !state.isReportsHistoryOpen;
      })
      .addCase(openPreviousReport, (state, action) => {
        state.currentOpenHistoryReportIndex += 1;
      })
      .addCase(openNextReport, (state, action) => {
        state.currentOpenHistoryReportIndex -= 1;
      })
      .addCase(openReportDraftsDialog, (state, action) => {
        state.isReportDraftsDialogOpen = true;
      })
      .addCase(closeReportDraftsDialog, (state, action) => {
        state.isReportDraftsDialogOpen = false;
        state.additionalUserSalaryRows = {};
        state.rowComments = {};
        state.weekIndexedEmployeeNamesToAdd = {};
        state.selectedReportDraftId = '';
        state.currentOpenHistoryReportIndex = 0;
      })
      .addCase(setSelectedReportDraftId, (state, action) => {
        state.selectedReportDraftId = action.payload;
      })
      .addCase(setInitialReportEditValuesOfDraft, (state, action) => {
        const reportDraftData = action.payload;

        const {
          fromDate,
          toDate,
          reportType,
          tableDataByWeekPeriod,
          totals,
          userAdditionalSalaryRows,
          rowComments,
        } = reportDraftData;

        state.reportBasicData = {
          fromDate,
          toDate,
          reportType,
        };

        state.reportUserRowTotals = arrayToMap(Object.values(totals), 'userId');

        state.rowComments = rowComments;

        state.additionalUserSalaryRows = userAdditionalSalaryRows;

        state.tableDataByWeekPeriod = tableDataByWeekPeriod;
      })
      .addCase(toggleRecordBasedReductions, (state, action) => {
        state.isRecordBasedReductionsActive =
          !state.isRecordBasedReductionsActive;
      })
      .addCase(openConfirmReportDraftDeleteDialog, (state, action) => {
        state.isConfirmReportDraftDeleteDialogOpen = true;
      })
      .addCase(closeConfirmReportDraftDeleteDialog, (state, action) => {
        state.isConfirmReportDraftDeleteDialogOpen = false;
      })
      .addCase(toggleIsReportProjectRowsEnabled, (state, action) => {
        state.isReportProjectHourRowsEnabled =
          !state.isReportProjectHourRowsEnabled;
      });
  },
});
