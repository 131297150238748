import { createSelector } from '@reduxjs/toolkit';
import processTree from 'scenes/ProjectBank/components/processTree';
import { selectProjectUsers } from 'store/projectUsers/selectors/projectUsers.selectors';
import { selectProjects } from 'store/projects/selectors/projectsSelectors';

const selectProjectBankState = (state) => state.projectBank;

export const selectIsProjectTreeLoading = createSelector(
  [selectProjectBankState],
  (state) => state.projectTreeLoading
);

export const selectProjectBankFilesFilePermissions = createSelector(
  [selectProjectBankState],
  (state) => state.filesFilePermissions.entities
);

export const selectSelectedProjectBankFolderPath = createSelector(
  [selectProjectBankState],
  (state) => state.selectedFolderPath
);

export const selectSelectedProjectBankFolderId = createSelector(
  [selectProjectBankState],
  (state) => state.selectedFolderId
);

export const selectProjectBankSortBy = createSelector(
  [selectProjectBankState],
  (state) => state.sortBy
);

export const selectSelectedTilaajavastuuBusinessId = createSelector(
  [selectProjectBankState],
  (state) => state.selectedTilaajavastuuBusinessId
);

export const selectIsLoadingTilaajavastuuDocuments = createSelector(
  [selectProjectBankState],
  (state) => state.isLoadingTilaajavastuuDocuments
);
export const selectSelectedPreviewTilaajavastuuDocument = createSelector(
  [selectProjectBankState],
  (state) => state.selectedPreviewTilaajavastuuDocument
);

export const selectTilaajavastuuDocumentEntities = createSelector(
  [selectProjectBankState],
  (state) => state.tilaajavastuuDocuments.entities
);

export const selectSelectedTilaajavastuuFolderProjectId = createSelector(
  [selectProjectBankState],
  (state) => state.selectedTilaajavastuuFolderProjectId
);

export const selectOpenedProjectTreeFolderIds = createSelector(
  [selectProjectBankState],
  (state) => state.openedProjectTreeFolderIds
);

export const selectIsPdfPreviewDialogOpen = createSelector(
  [selectProjectBankState],
  (state) => state.isPdfPreviewDialogOpen
);

export const selectProjectBankViewMode = createSelector(
  [selectProjectBankState],
  (state) => state.projectBankViewMode
);

export const selectSelectedProjectBankFiles = createSelector(
  [selectProjectBankState],
  (state) => state.selectedProjectBankFiles
);

export const selectIsProjectBankFileSelectDialogOpen = createSelector(
  [selectProjectBankState],
  (state) => state.isProjectBankFileSelectDialogOpen
);

export const selectTilaajavastuuDocumentsArray = createSelector(
  [selectTilaajavastuuDocumentEntities],
  (entities) => Object.values(entities)
);

export const selectFoundTilaajavastuuDocumentsArray = createSelector(
  [selectTilaajavastuuDocumentsArray],
  (tilaajavastuuDocuments) =>
    tilaajavastuuDocuments.filter((document) => document.status !== 'NOT_FOUND')
);

export const selectInsufficientTilaajavastuuDocumentsBusinessIdsArrayByProjectId =
  createSelector(
    [selectFoundTilaajavastuuDocumentsArray, (_, args) => args],
    (tilaajavastuuDocuments, args) => {
      const notAlarmedStatuses = ['Ok', 'Huomioi', 'Tietoja odotetaan'];
      return tilaajavastuuDocuments
        .filter(
          (document) =>
            !notAlarmedStatuses.includes(document.status) &&
            document.projectId === args &&
            !document.deletedAt
        )
        .map((document) => document.businessId.split('#')[0]);
    }
  );

export const selectProjectBankFilesFilePermissionsArray = createSelector(
  [selectProjectBankFilesFilePermissions],
  (filePermissions) =>
    Object.values(filePermissions).filter(
      (filePermission) => !filePermission.deletedAt
    )
);

const selectProjectFolderFilePermissions = createSelector(
  [selectProjectBankState],
  (state) => state.projectFolderFilePermissions.entities
);

export const selectSelectedProjectBankFolder = createSelector(
  [selectProjectFolderFilePermissions, selectSelectedProjectBankFolderId],
  (folders, selectedFolderId) => folders[selectedFolderId]
);

export const selectProjectFolderFilePermissionsArray = createSelector(
  [selectProjectFolderFilePermissions],
  (filePermissions) =>
    Object.values(filePermissions).filter(
      (filePermission) => !filePermission.deletedAt
    )
);

export const selectProjectFolderFilePermissionsArrayWithJoinedData =
  createSelector(
    [
      selectProjectFolderFilePermissionsArray,
      selectProjects,
      selectProjectUsers,
    ],
    (filePermissions, projects, users) =>
      filePermissions.map((filePermission) => ({
        ...filePermission,
        project: projects[filePermission.project]
          ? projects[filePermission.project]
          : { id: filePermission.project },
        user: users[filePermission.user]
          ? users[filePermission.user]
          : { id: filePermission.user },
      }))
  );

export const selectProjectBankFilesFilePermissionsArrayWithJoinedData =
  createSelector(
    [
      selectProjectBankFilesFilePermissionsArray,
      selectProjectUsers,
      selectProjects,
    ],
    (files, users, projects) =>
      files.map((file) => ({
        ...file,
        user: users[file.user] ? users[file.user] : { id: file.user },
        project: projects[file.project]
          ? projects[file.project]
          : { id: file.project },
      }))
  );

export const selectSelectedProjectBankFolderContentFiles = createSelector(
  [
    selectSelectedProjectBankFolder,
    selectProjectBankFilesFilePermissionsArrayWithJoinedData,
  ],
  (selectedFolder, files) => {
    if (selectedFolder) {
      if (selectedFolder.name === 'images') {
        // handle showing images from possible duplicate images folders in project

        const filesInImagesFoldersOfProject = files.filter((file) => {
          const fileInImagesFolder =
            file && file.path && file.path.includes('images') ? true : false;
          return (
            file.parent === selectedFolder.id ||
            (fileInImagesFolder && file.project.id === selectedFolder.project)
          );
        });
        return filesInImagesFoldersOfProject;
      } else {
        return files.filter((file) => file.parent === selectedFolder.id);
      }
    } else {
      return [];
    }
  }
);

export const selectSelectedProjectBankFolderChildFolders = createSelector(
  [
    selectProjectFolderFilePermissionsArrayWithJoinedData,
    selectSelectedProjectBankFolderId,
    selectSelectedProjectBankFolderPath,
  ],
  (folderFilePermissions, selectedFolderId, selectedFolderPath) =>
    folderFilePermissions.filter(
      (folder) =>
        folder.parent === selectedFolderId ||
        folder.parentPath === selectedFolderPath
    )
);

export const selectSelectedProjectBankFolderChildProjectFolders =
  createSelector([selectSelectedProjectBankFolderChildFolders], (folders) => {
    const childProjects = folders.filter(
      (folder) =>
        (folder.businessId && folder.key.split('/').length === 3) ||
        (!folder.businessId && folder.key.split('/').length === 2)
    );

    return Object.values(processTree(childProjects));
  });

export const selectSelectedProjectBankFolderChildNormalFolders = createSelector(
  [selectSelectedProjectBankFolderChildFolders],
  (folders) =>
    folders.filter(
      (folder) =>
        (folder.businessId && folder.key.split('/').length > 3) ||
        (!folder.businessId && folder.key.split('/').length > 2)
    )
);

export const selectIsFolderFilesLoading = createSelector(
  [selectProjectBankState],
  (state) => state.isFolderFilesLoading
);

export const selectAllCurrentProjectBankFolderContentItems = createSelector(
  [
    selectSelectedProjectBankFolderContentFiles,
    selectSelectedProjectBankFolderChildProjectFolders,
    selectSelectedProjectBankFolderChildNormalFolders,
    selectProjectBankSortBy,
  ],
  (files, projects, folders, sortBy) => {
    const allItems = [...files, ...projects, ...folders];

    let sorted = [];

    switch (sortBy) {
      case 'name-ascending':
        sorted = [...allItems];
        sorted = sorted.sort((a, b) =>
          checkName(a) < checkName(b) ? -1 : checkName(a) > checkName(b) ? 1 : 0
        );
        return sorted;
      case 'name-descending':
        sorted = [...allItems];
        sorted = sorted.sort((a, b) =>
          checkName(a) > checkName(b) ? -1 : checkName(a) < checkName(b) ? 1 : 0
        );
        return sorted;
      case 'date-ascending':
        sorted = [...allItems];
        sorted = sorted.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
        return sorted;
      case 'date-descending':
        sorted = [...allItems];
        sorted = sorted.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        return sorted;
      case 'creator-ascending':
        sorted = [...allItems];
        sorted = sorted.sort((a, b) =>
          a.user.firstName.toLowerCase() < b.user.firstName.toLowerCase()
            ? -1
            : a.user.firstName.toLowerCase() > b.user.firstName.toLowerCase()
            ? 1
            : 0
        );
        return sorted;
      case 'creator-descending':
        sorted = [...allItems];
        sorted = sorted.sort((a, b) =>
          a.user.firstName.toLowerCase() > b.user.firstName.toLowerCase()
            ? -1
            : a.user.firstName.toLowerCase() < b.user.firstName.toLowerCase()
            ? 1
            : 0
        );
        return sorted;
      default:
        sorted = [...allItems];
    }

    return sorted;
  }
);

const checkName = (filePermission) => {
  let name;
  if (!filePermission.isRoot) {
    name = filePermission.name.toLowerCase();
  } else {
    name = filePermission.project.name.toLowerCase();
  }
  return name;
};

export const selectInitialProjectBankPath = createSelector(
  [selectProjectBankState],
  (state) => state.initialProjectBankPath
);

export const selectProjectBankProjectTree = createSelector(
  [
    selectProjectFolderFilePermissionsArrayWithJoinedData,
    selectInitialProjectBankPath,
  ],
  (folderFilePermissions, urlPath) => {
    if (urlPath) {
      let pathFolders = [];
      let tempPath = urlPath.split('/');
      while (tempPath.length > 1) {
        pathFolders.push(tempPath.join('/'));
        tempPath = tempPath.slice(0, -1);
      }
      pathFolders.reverse();
    }

    const filteredProjects = folderFilePermissions.filter((folder) =>
      folder.path?.startsWith(urlPath)
    );

    const tree = processTree(filteredProjects, urlPath);

    return tree;
  }
);

export const selectFolderTree = createSelector(
  [selectProjectBankProjectTree, selectInitialProjectBankPath],
  (projectTree, urlPath) => {
    const treeFolders = Object.values(projectTree);

    const rootFolder = treeFolders.find((folder) => folder.path === urlPath);

    return rootFolder;
  }
);
