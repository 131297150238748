import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Config from 'Config';
import { createSignedRequest } from 'utils/createSignedRequest';
import { getUserProjectsAndResources } from 'store/projects/services/getUserProjectsAndResources.service';
import { getProjectToRedirectTo } from 'store/projects/services/getProjectToRedirectTo.service';

export const loadUserProjects = createAsyncThunk(
  'projects/loadUserProjects',
  async () => {
    const apiPath = 'user-projects';

    const request = {
      url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
      host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
      path: apiPath,
      method: 'GET',
      region: Config.AWS_REGION,
      service: 'execute-api',
    };

    const signedRequest = await createSignedRequest(request);

    const response = await axios(signedRequest);

    return response.data;
  }
);

export const loadUserProjectsAndResources = createAsyncThunk(
  'projects/loadUserProjectsAndResources',
  async (history) => {
    console.log("history:", history.location)
    let projectResponse = null;
    const userProjectsResources = await getUserProjectsAndResources();
    if ((history.location.pathname === '' ||
      history.location.pathname === '/') && history.location.search === '') {
      projectResponse = await getProjectToRedirectTo();
      const residentResourcesInProject = userProjectsResources.resources.filter(res => res.project === projectResponse.id && res.employmentType === 'RESIDENT');
      const residentResource = residentResourcesInProject[0];
      if (projectResponse && projectResponse.isAdmin) {
        projectResponse = null;
      } else if (projectResponse && projectResponse.id && !residentResource) {
        history.replace(`/project/${projectResponse.projectKey}`);
      } else if (projectResponse && projectResponse.id && residentResource) {
        history.replace(`/project/${projectResponse.projectKey}/bulletin/${projectResponse.id}`);
      }
    }

    return { projects: userProjectsResources.projects, resources: userProjectsResources.resources, projectToRedirectTo: projectResponse };
  }
);
