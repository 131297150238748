import { createAction } from '@reduxjs/toolkit';

export const setReportGeneratedFromMode = createAction(
  'reports/setReportGeneratedFromMode'
);

export const openReportSendDialog = createAction(
  'reports/openReportSendDialog'
);

export const closeReportSendDialog = createAction(
  'reports/closeReportSendDialog'
);

export const closeReportPreviewDialog = createAction(
  'reports/closeReportPreviewDialog'
);

export const addSalaryRowsRecipientEmail = createAction(
  'reports/addSalaryRowsRecipientEmail'
);

export const removeSalaryRowsRecipientEmail = createAction(
  'reports/removeSalaryRowsRecipientEmail'
);

export const removeReportPreviewUserRow = createAction(
  'reportPreview/removeReportPreviewUserRow'
);

export const setEmployeeNameToAdd = createAction(
  'reportPreview/setEmployeeNameToAdd'
);

export const setDayWorkHoursOfEmployeeToAdd = createAction(
  'reportPreview/setDayWorkHoursOfEmployeeToAdd'
);

export const initAddEmployeeForm = createAction(
  'reportPreview/initAddEmployeeForm'
);

export const addManualUserRowTotals = createAction(
  'reportPreview/addManualUserRowTotals'
);

export const openRowCommentDialog = createAction(
  'reportPreview/openRowCommentDialog'
);

export const closeRowCommentDialog = createAction(
  'reportPreview/closeRowCommentDialog'
);

export const setRowComment = createAction('reportPreview/setRowComment');

export const openAdditionalUserSalaryRowDialog = createAction(
  'reportPreview/openAdditionalUserSalaryRowDialog'
);

export const closeAdditionalUserSalaryRowDialog = createAction(
  'reportPreview/closeAdditionalUserSalaryRowDialog'
);

export const addAdditionalUserSalaryRow = createAction(
  'reportPreview/addAdditionalUserSalaryRow'
);

export const removeReportPreviewUserAdditionalSalaryRow = createAction(
  'reportPreview/removeReportPreviewUserAdditionalSalaryRow'
);

export const openReportsHistory = createAction('reports/openReportHistory');

export const openPreviousReport = createAction(
  'reportsHistory/openPreviousReport'
);

export const openNextReport = createAction('reportsHistory/openNextReport');

export const openReportDraftsDialog = createAction(
  'reports/openReportDraftsDialog'
);

export const closeReportDraftsDialog = createAction(
  'reports/closeReportDraftsDialog'
);

export const setSelectedReportDraftId = createAction(
  'reportDrafts/setSelectedReportDraftId'
);

export const setInitialReportEditValuesOfDraft = createAction(
  'reportDrafts/setInitialReportEditValuesOfDraft'
);

export const toggleRecordBasedReductions = createAction(
  'reports/toggleRecordBasedReductions'
);

export const closeConfirmReportDraftDeleteDialog = createAction(
  'reportDrafts/closeConfirmReportDraftDeleteDialog'
);
export const openConfirmReportDraftDeleteDialog = createAction(
  'reportDrafts/openConfirmReportDraftDeleteDialog'
);

export const toggleIsReportProjectRowsEnabled = createAction(
  'reportDrafts/toggleIsReportProjectRowsEnabled'
);
