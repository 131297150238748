import { createAsyncThunk } from '@reduxjs/toolkit';
import { postCreateDefectAddition } from 'scenes/DefectList/services/post-create-defect-addition.service';
import {
  selectDefectImagesToAdd,
  selectSelectedAdditionDefectItemId,
} from '../selectors/defect-list.selectors';

export const createDefectAddition = createAsyncThunk(
  'defectLists/createDefectAddition',
  async (_, { getState }) => {
    const additionDefectItemId = selectSelectedAdditionDefectItemId(getState());

    const defectImagesToAdd = selectDefectImagesToAdd(getState());

    const updateData = await postCreateDefectAddition(
      additionDefectItemId,
      defectImagesToAdd
    );

    return updateData;
  }
);
