import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
  clearExpensesTrackingData,
  clearExpenseTrackingForDateChange,
} from 'scenes/ExpenseTracking/store/actions/expenses-tracking.actions';
import {
  updateInvoicesFromGraphqlMutate,
  clearInvoiceData,
  removeLastInvoiceError,
} from './actions/invoices.actions';
import { loadProjectInvoices } from './thunks/loadProjectInvoices';
import { loadProjectSaleInvoices } from './thunks/loadProjectSaleInvoices';
import { loadProjectSaleInvoiceTables } from './thunks/loadProjectSaleInvoiceTables';
import * as actions from './actions/invoices.actions';
import { patchApproveRejectInvoice } from './thunks/patchApproveRejectInvoice';
import { patchUpdateInvoice } from './thunks/patchUpdateInvoice';
import { patchUpdateMultipleInvoices } from './thunks/patchUpdateMultipleInvoices';
import { createSaleInvoice } from './thunks/createSaleInvoice';
import { loadInvoiceSortingRules } from './thunks/loadInvoiceSortingRules';
import { patchUpdateInvoiceSortingRule } from './thunks/patchUpdateInvoiceSortingRule';
import { patchCreateInvoiceSortingRule } from './thunks/patchCreateInvoiceSortingRule';
import { enqueueSnackbar } from 'notistack';
import { loadInvoiceSortingRule } from './thunks/loadInvoiceSortingRule';
import { loadInvoiceSortRules } from './thunks/loadInvoiceSortRules';
import { patchCreateInvoiceSortRule } from './thunks/patchCreateInvoiceSortRule';
import { deleteInvoiceSortRule } from './thunks/deleteInvoiceSortRule';
import { patchSortMultipleInvoicesBySortRule } from './thunks/patchSortMultipleInvoicesBySortRule';
import { loadProjectInvoicesByRows } from './thunks/loadProjectInvoicesByRows';

const invoicesAdapter = createEntityAdapter();
const invoiceRowsAdapter = createEntityAdapter();
const saleInvoicesAdapter = createEntityAdapter();
const saleInvoiceTablesAdapter = createEntityAdapter();
const invoiceSortingRulesAdapter = createEntityAdapter();
const invoiceSortRulesAdapter = createEntityAdapter();

const resetState = {
  invoices: invoicesAdapter.getInitialState(),
  invoiceRows: {},
  saleInvoices: saleInvoicesAdapter.getInitialState(),
  saleInvoiceTables: saleInvoiceTablesAdapter.getInitialState(),
  invoiceSortingRules: invoiceSortingRulesAdapter.getInitialState(),
  invoiceSortRules: invoiceSortRulesAdapter.getInitialState(),
  loadingInvoiceSortingRules: false,
  loadingInvoiceSortRules: false,
  isInvoicesLoaded: false,
  selectedInvoice: '',
  loading: false,
  error: null,
  invoiceUpdateError: null,
  invoiceSortingRuleError: null,
  invoiceApprovalError: null,
  invoiceErrors: [],
};


export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState: {
    invoices: invoicesAdapter.getInitialState(),
    invoiceRows: {},
    saleInvoices: saleInvoicesAdapter.getInitialState(),
    saleInvoiceTables: saleInvoiceTablesAdapter.getInitialState(),
    invoiceSortingRules: invoiceSortingRulesAdapter.getInitialState(),
    invoiceSortRules: invoiceSortRulesAdapter.getInitialState(),
    loadingInvoiceSortingRules: false,
    loadingInvoiceSortRules: false,
    isInvoicesLoaded: false,
    selectedInvoice: '',
    loading: false,
    error: null,
    invoiceUpdateError: null,
    invoiceSortingRuleError: null,
    invoiceApprovalError: null,
    invoiceErrors: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadProjectInvoices.pending, (state) => {
        state.loading = true;
        state.isInvoicesLoaded = false;
      })
      .addCase(loadProjectInvoices.fulfilled, (state, action) => {
        invoicesAdapter.setMany(state.invoices, action.payload);
        state.loading = false;
        state.isInvoicesLoaded = true;
      })
      .addCase(loadProjectInvoices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        state.isInvoicesLoaded = false;
      })
      .addCase(loadProjectInvoicesByRows.pending, (state) => {
        state.loading = true;
        state.isInvoicesLoaded = false;
      })
      .addCase(loadProjectInvoicesByRows.fulfilled, (state, action) => {
        invoicesAdapter.setMany(state.invoices, action.payload.invoices);

        action.payload.invoiceRows.forEach((row) => {
          const { invoice } = row;

          if (!state.invoiceRows[invoice]) {
            state.invoiceRows[invoice] = [];
          }

          state.invoiceRows[invoice].push(row);
        });

        // Update the loading state
        state.loading = false;
        state.isInvoicesLoaded = true;
      })
      .addCase(loadProjectInvoicesByRows.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        state.isInvoicesLoaded = false;
      })
      .addCase(loadProjectSaleInvoices.pending, (state) => {
        state.loading = true;
        state.isInvoicesLoaded = false;
      })
      .addCase(loadProjectSaleInvoices.fulfilled, (state, action) => {
        saleInvoicesAdapter.setAll(state.saleInvoices, action.payload);
        // state.loading = false;
        // state.isInvoicesLoaded = true;
      })
      .addCase(loadProjectSaleInvoices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        state.isInvoicesLoaded = false;
      })
      .addCase(loadProjectSaleInvoiceTables.pending, (state) => {
        state.loading = true;
        state.isInvoicesLoaded = false;
      })
      .addCase(loadProjectSaleInvoiceTables.fulfilled, (state, action) => {
        saleInvoiceTablesAdapter.setMany(
          state.saleInvoiceTables,
          action.payload
        );
        state.loading = false;
      })
      .addCase(loadProjectSaleInvoiceTables.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(clearExpensesTrackingData, (state) => {
        return resetState;
      })
      .addCase(clearExpenseTrackingForDateChange, (state) => {
        state.invoices = invoicesAdapter.getInitialState();
        state.saleInvoices = saleInvoicesAdapter.getInitialState();
      })
      .addCase(updateInvoicesFromGraphqlMutate, (state, action) => {
        console.log('updateInvoicesFromGraphqlMutate', action);
        if (action.payload.length) {
          invoicesAdapter.upsertMany(state.invoices, action.payload);
        } else {
          invoicesAdapter.upsertOne(state.invoices, action.payload);
        }
      })
      .addCase(actions.setInvoiceLoadedAmount, (state, action) => {
        state.invoiceLoadedAmount = action.payload;
      })
      .addCase(actions.setSaleInvoiceLoadedAmount, (state, action) => {
        state.saleInvoiceLoadedAmount = action.payload;
      })
      .addCase(patchApproveRejectInvoice.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(patchApproveRejectInvoice.fulfilled, (state, action) => {
        console.log('RETURN PAYLOAD IN SLICE: ', action.payload);
        if (action.payload.length) {
          invoicesAdapter.upsertMany(state.invoices, action.payload);
        } else {
          invoicesAdapter.upsertOne(state.invoices, action.payload);
        }
        state.loading = false;
        enqueueSnackbar('Lasku hyväksytty/hylätty', {
          variant: 'success',
        });
      })
      .addCase(patchApproveRejectInvoice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        state.invoiceApprovalError = action.error;
        state.invoiceErrors.push(action.error);
        enqueueSnackbar('Virhe laskun hyväksynnässä/hylkäyksessä', {
          variant: 'error',
        });
      })
      .addCase(createSaleInvoice.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createSaleInvoice.fulfilled, (state, action) => {
        state.loading = false;
        enqueueSnackbar('Myyntilasku tallennettu', {
          variant: 'success',
        });
      })
      .addCase(createSaleInvoice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        enqueueSnackbar('Virhe luodessa myyntilaskua', {
          variant: 'error',
        });
      })
      .addCase(patchUpdateInvoice.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(patchUpdateInvoice.fulfilled, (state, action) => {
        console.log('RETURN PAYLOAD IN SLICE: ', action.payload);
        if (action.payload.length) {
          invoicesAdapter.setMany(state.invoices, action.payload);
        } else {
          invoicesAdapter.setOne(state.invoices, action.payload);
        }
        state.loading = false;
        enqueueSnackbar('Lasku tallennettu', {
          variant: 'success',
        });
      })
      .addCase(patchUpdateInvoice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        state.invoiceUpdateError = action.error;
        state.invoiceErrors.push(action.error);
        enqueueSnackbar('Virhe tallennettaessa laskua', {
          variant: 'error',
        });
      })
      .addCase(patchUpdateMultipleInvoices.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(patchUpdateMultipleInvoices.fulfilled, (state, action) => {
        console.log('RETURN PAYLOAD IN SLICE: ', action.payload);
        if (action.payload.length) {
          invoicesAdapter.upsertMany(state.invoices, action.payload);
        } else {
          invoicesAdapter.upsertOne(state.invoices, action.payload);
        }
        state.loading = false;
        enqueueSnackbar('Laskut tallennettu', {
          variant: 'success',
        });
      })
      .addCase(patchUpdateMultipleInvoices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        state.invoiceUpdateError = action.error;
        state.invoiceErrors.push(action.error);
        enqueueSnackbar('Virhe tallennettaessa laskuja', {
          variant: 'error',
        });
      })
      .addCase(removeLastInvoiceError, (state) => {
        state.invoiceErrors.shift();
        console.log('ERROR ARRAY', state.invoiceErrors);
      })

      .addCase(loadInvoiceSortRules.pending, (state) => {
        state.loadingInvoiceSortRules = true;
      })
      .addCase(loadInvoiceSortRules.fulfilled, (state, action) => {
        invoiceSortRulesAdapter.setAll(state.invoiceSortRules, action.payload);

        state.loadingInvoiceSortingRules = false;
      })
      .addCase(loadInvoiceSortRules.rejected, (state, action) => {
        state.loadingInvoiceSortRules = false;
      })
      .addCase(patchCreateInvoiceSortRule.pending, (state) => {
        state.loadingInvoiceSortRules = true;
      })
      .addCase(patchCreateInvoiceSortRule.fulfilled, (state, action) => {
        invoiceSortRulesAdapter.upsertOne(
          state.invoiceSortRules,
          action.payload
        );
        state.loadingInvoiceSortRules = false;
        enqueueSnackbar('Lajittelusääntö tallennettu', {
          variant: 'success',
        });
      })
      .addCase(patchCreateInvoiceSortRule.rejected, (state, action) => {
        state.loadingInvoiceSortRules = false;
        state.error = action.error;
        enqueueSnackbar('Virhe tallennettaessa lajittelusääntöjä', {
          variant: 'error',
        });
      })
      .addCase(patchSortMultipleInvoicesBySortRule.pending, (state) => {})
      .addCase(
        patchSortMultipleInvoicesBySortRule.fulfilled,
        (state, action) => {
          console.log('action.payload', action.payload);
          invoicesAdapter.setMany(state.invoices, action.payload);

          enqueueSnackbar('Säännönmukaiset laskut lajiteltu', {
            variant: 'success',
          });
        }
      )
      .addCase(
        patchSortMultipleInvoicesBySortRule.rejected,
        (state, action) => {
          state.error = action.error;
          enqueueSnackbar('Virhe säännönmukaisten laskujen lajittelussa', {
            variant: 'error',
          });
        }
      )
      .addCase(deleteInvoiceSortRule.pending, (state) => {
        state.loadingInvoiceSortRules = true;
      })
      .addCase(deleteInvoiceSortRule.fulfilled, (state, action) => {
        console.log('action.payload', action.payload);
        invoiceSortRulesAdapter.removeOne(
          state.invoiceSortRules,
          action.payload.id
        );
        state.loadingInvoiceSortRules = false;
        enqueueSnackbar('Lajittelusääntö poistettu', {
          variant: 'success',
        });
      })
      .addCase(deleteInvoiceSortRule.rejected, (state, action) => {
        state.loadingInvoiceSortRules = false;
        state.error = action.error;
        enqueueSnackbar('Virhe poistettaessa lajittelusääntöä', {
          variant: 'error',
        });
      });
  },
});
