import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProjectEmployeesLatestRecordData } from 'scenes/DefectList/services/get-project-employees-latest-record-data.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const loadProjectEmployeesLatestRecordData = createAsyncThunk(
  'defectLists/loadProjectEmployeesLatestRecordData',
  async (_, { getState }) => {
    const selectedProjectId = selectSelectedProjectId(getState());
    const resourcesLatestRecordData = await getProjectEmployeesLatestRecordData(
      selectedProjectId
    );

    return resourcesLatestRecordData;
  }
);
