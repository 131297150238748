import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { getProjectInvoicesByRowsByDate } from '../services/getProjectInvoicesByRowsByDate';

export const loadProjectInvoicesByRows = createAsyncThunk(
  'invoices/loadProjectInvoicesByRows',
  async (params, { getState, dispatch }) => {
    const state = getState();

    const { fromDate, toDate } = params;

    const selectedProjectId = selectSelectedProjectId(state);

    let invoices = [];
    let invoiceRows = [];

    let response;

    do {
      if (fromDate && toDate) {
        response = await getProjectInvoicesByRowsByDate(
          selectedProjectId,
          fromDate,
          toDate,
          response?.LastEvaluatedKey
        );
        invoices.push(...response.Invoices);
        invoiceRows.push(...response.InvoiceRows);
      } 

    } while (response.LastEvaluatedKey);

    return {
      invoices,
      invoiceRows,
    };
  }
);
