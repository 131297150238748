import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMediaFilePermissionsByUserAndProject } from '../../services/getMediaFilePermissionsByUserAndProject.service';

export const loadMediaFilePermissionsByUserAndProject = createAsyncThunk(
  'file-permissions/get-media-file-permissions-by-user-and-project',
  async ({ projectId }) => {
    let lastEvaluatedKey = undefined;
    let filePermissions = [];
    let records = [];
    let resources = [];

    do {
      const response = await getMediaFilePermissionsByUserAndProject(
        projectId,
        lastEvaluatedKey
      );

      lastEvaluatedKey = response.lastEvaluatedKey;
      filePermissions.push(response.filePermissions);
      records.push(response.records);
      resources.push(response.resources);
    } while (lastEvaluatedKey);

    return {
      filePermissions: filePermissions.flat(),
      records: records.flat(),
      resources: resources.flat(),
    };
  }
);
